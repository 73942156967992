import React from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';

const StyledDiv = styled.div`
  max-width: 400px;
  text-align: center;
  margin: 0 auto;
`

const EvoswarmPage = () => (
  <Layout defaultHeader="Page Not Found">
    <StyledDiv>
        <h2>Error 404</h2>
        <p>Sorry, there is no page here.</p>
    </StyledDiv>
  </Layout>
)

export default EvoswarmPage